export const KITE_WIDGET_STREAM_CREDENTIALS_KEY = '@wx/KITE_WIDGET_STREAM_CREDENTIALS_KEY';
export const WIDGET_INSTANCE = '@wx/WIDGET_SESSION_INSTANCE';
export const WIDGET_IS_OPEN = '@wx/WIDGET_IS_OPEN';
export const WIDGET_COLOR = '@wx/WIDGET_COLOR';

export const WIDGET_MESSAGES = Object.freeze({
  PREFIX: 'wx-widget:',
  RESUME: 'resume',
  CLOSE: 'close',
  STREAM_INFO: 'streamInfo',
  STREAM_SIGN_OUT: 'streamSignOut',
  KITE_SUSPENDED: 'kiteSuspended',
  KITE_LOADED: 'kiteLoaded',
  IS_MOBILE: 'isMobile',
  LOADED_IN_KITE_WIDGET: 'loadedInKiteWidget',
  SIGN_IN: 'signIn',
  MAXIMIZE: 'maximize',
  MINIMIZE: 'minimize',
  CONFERENCE_STARTED: 'conferenceStarted',
  CONFERENCE_ENDED: 'conferenceEnded',
  UNREAD_STREAM_MESSAGES_EXIST: 'unreadStreamMessagesExist',
  RENDER: 'render',
  IS_OPEN: 'isOpen',
});
