import {WIDGET_INSTANCE, WIDGET_MESSAGES} from './constants';

import {loadCSS} from './helpers/DOMHelpers';
import {IFrameHelper} from './helpers/IFrameHelper';
import {initLocalStorageMonitoring} from './helpers/localStorageHelpers';
import {INSTANCE_ID} from './instance';
import {state} from './state';

loadCSS();
initLocalStorageMonitoring();

localStorage.setItem(WIDGET_INSTANCE, INSTANCE_ID);

window.addEventListener('focus', () => {
  state.widgetIsActive = true;
  localStorage.setItem(WIDGET_INSTANCE, INSTANCE_ID);
  IFrameHelper.sendMessage({event: WIDGET_MESSAGES.RESUME});
});

window.addEventListener('resize', () => {
  state.isMobile = window.matchMedia('(max-width: 668px)').matches;
});

export const runSDK = ({baseUrl, kiteId}) => {
  if (state.widgetStarted) {
    return;
  }

  const wxSettings = window.wxSettings || {};

  const baseUrlFixed = baseUrl.replace(/x-bees\.biz/, 'app.x-bees.com');

  state.widgetStarted = true;
  state.baseUrl = baseUrlFixed;
  state.kiteId = kiteId;
  state.position = wxSettings.position === 'left' ? 'left' : 'right';

  IFrameHelper.createWidgetHolder();
};
