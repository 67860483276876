import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import {WIDGET_MESSAGES} from '../constants';

import {state} from '../state';
import {loadCSS} from './DOMHelpers';
import {
  body,
  bubbleHolder,
  chatBubble,
  createBubbleHolder,
  createBubbleIcon,
  createNotificationBubble,
  createTopBar,
  topBarCloseBtn,
  widgetHolder,
} from './bubbleHelpers';

export const IFrameHelper = {
  createWidgetHolder: () => {
    widgetHolder.classList.add(
      'wx-widget-holder',
      'wx--hide',
      `wx-elements--${state.position}`,
      state.maximized && 'wx--maximized',
    );

    body.appendChild(widgetHolder);
    IFrameHelper.initPostMessageCommunication();
    IFrameHelper.preventDefaultScroll();

    createBubbleHolder();
    createTopBar();

    const chatIcon = createBubbleIcon({
      className: 'wx-widget-bubble',
      target: chatBubble,
    });

    bubbleHolder.appendChild(chatIcon);
    bubbleHolder.appendChild(createNotificationBubble());

    bubbleHolder.addEventListener('click', () => (state.isOpen = !state.isOpen));
    topBarCloseBtn.addEventListener('click', () => (state.isOpen = false));
  },
  createFrame: () => {
    const iframe = document.createElement('iframe');
    iframe.src = `${state.baseUrl}/kite/${state.kiteId}`;
    iframe.id = 'wx_live_chat_widget';
    iframe.setAttribute('allow', 'camera;microphone');
    iframe.setAttribute(
      'sandbox',
      'allow-forms allow-scripts allow-popups allow-same-origin allow-top-navigation allow-downloads',
    );

    widgetHolder.appendChild(iframe);
  },
  renderFrame: () => {
    if (!state.iframeRendered) {
      IFrameHelper.sendMessage({event: WIDGET_MESSAGES.RENDER});
      state.iframeRendered = true;
    }
  },
  removeFrame: () => {
    const appFrame = IFrameHelper.getAppFrame();
    if (appFrame) {
      state.iframeRendered = false;
      state.isOpen = false;
      // iframe reload
      appFrame.src = appFrame.src;
    }
  },
  getAppFrame: () => document.getElementById('wx_live_chat_widget'),
  sendMessage: (message) => {
    const element = IFrameHelper.getAppFrame();
    element && element.contentWindow.postMessage(`${WIDGET_MESSAGES.PREFIX}${JSON.stringify(message)}`, '*');
  },
  initPostMessageCommunication: () => {
    window.addEventListener('message', (e) => {
      if (isString(e.data) && e.data.startsWith(WIDGET_MESSAGES.PREFIX)) {
        const message = JSON.parse(e.data.replace(WIDGET_MESSAGES.PREFIX, ''));
        if (isFunction(IFrameHelper.events[message.event])) {
          IFrameHelper.events[message.event](message);
        }
      }
    });
  },
  preventDefaultScroll: () => {
    widgetHolder.addEventListener('wheel', (event) => {
      const deltaY = event.deltaY;
      const contentHeight = widgetHolder.scrollHeight;
      const visibleHeight = widgetHolder.offsetHeight;
      const scrollTop = widgetHolder.scrollTop;

      if ((scrollTop === 0 && deltaY < 0) || (visibleHeight + scrollTop === contentHeight && deltaY > 0)) {
        event.preventDefault();
      }
    });
  },

  setFrameHeightToFitContent: (extraHeight, isFixedHeight) => {
    const iframe = IFrameHelper.getAppFrame();
    const updatedIframeHeight = isFixedHeight ? `${extraHeight}px` : '100%';

    if (iframe) {
      iframe.setAttribute('style', `height: ${updatedIframeHeight} !important`);
    }
  },

  events: {
    kiteSuspended: () => {
      state.kiteSuspended = true;
    },

    kiteLoaded: ({widgetColor}) => {
      state.kiteLoaded = true;
      state.iframeRendered = state.isOpen;
      loadCSS(widgetColor);

      IFrameHelper.sendMessage({
        event: WIDGET_MESSAGES.LOADED_IN_KITE_WIDGET,
        isKiteWidget: true,
        isOpen: state.isOpen,
      });
    },

    close: () => {
      state.isOpen = false;
    },

    streamSignOut: async () => {
      state.unreadStreamMessagesExist = false;
    },

    signIn: () => {
      if (!state.inConference) {
        IFrameHelper.removeFrame();
      }
    },

    maximize: () => {
      state.maximized = true;
      widgetHolder.classList.add('wx--maximized');
    },
    minimize: () => {
      state.maximized = false;
      widgetHolder.classList.remove('wx--maximized');
    },

    conferenceStarted: () => {
      state.inConference = true;
    },

    conferenceEnded: () => {
      state.inConference = false;
    },
    unreadStreamMessagesExist: ({unreadStreamMessagesExist}) => {
      state.unreadStreamMessagesExist = unreadStreamMessagesExist;
    },
  },
};
