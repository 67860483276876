import {proxy} from 'valtio';
import {subscribeKey} from 'valtio/utils';

import {WIDGET_IS_OPEN, WIDGET_MESSAGES} from './constants';

import {IFrameHelper} from './helpers/IFrameHelper';
import {bubbleToggle, hideTopBar, toggleUnreadMark} from './helpers/bubbleHelpers';
import {isOpenFromLocalStorage} from './helpers/localStorageHelpers';

export const state = proxy({
  widgetStarted: false,
  baseUrl: '',
  kiteId: '',
  kiteLoaded: false,
  kiteSuspended: false,
  iframeRendered: false,
  isOpen: false,
  unreadStreamMessagesExist: false,
  widgetIsActive: true,
  isMobile: window.matchMedia('(max-width: 668px)').matches,
  position: 'right',
  maximized: false,
  topBarVisible: true,
  inConference: false,
});

subscribeKey(state, 'widgetStarted', () => {
  IFrameHelper.createFrame();
  if (state.inConference) {
    return;
  }

  if (state.widgetIsActive) {
    state.isOpen = isOpenFromLocalStorage();
  }
});

subscribeKey(state, 'unreadStreamMessagesExist', (value) => toggleUnreadMark(value));

subscribeKey(state, 'kiteLoaded', (value) => {
  if (!value) {
    return;
  }

  IFrameHelper.sendMessage({event: WIDGET_MESSAGES.IS_MOBILE, isMobile: state.isMobile});
  state.topBarVisible = false;
});

subscribeKey(state, 'iframeCreated', (value) => {
  if (!value) {
    state.kiteLoaded = false;
    state.kiteSuspended = false;
  }
});

subscribeKey(state, 'isMobile', (value) => {
  state.kiteLoaded && IFrameHelper.sendMessage({event: WIDGET_MESSAGES.IS_MOBILE, isMobile: state.isMobile});
});

subscribeKey(state, 'widgetIsActive', (value) => {
  if (state.inConference) {
    return;
  }

  if (value) {
    state.isOpen = isOpenFromLocalStorage();
  } else {
    state.isOpen = false;
  }
});

subscribeKey(state, 'isOpen', (isOpen) => {
  if (!state.iframeRendered && state.kiteLoaded) {
    IFrameHelper.renderFrame();
  } else {
    IFrameHelper.sendMessage({
      event: WIDGET_MESSAGES.IS_OPEN,
      isOpen: state.isOpen,
    });
  }

  bubbleToggle(isOpen);
  if (state.widgetIsActive) {
    localStorage.setItem(WIDGET_IS_OPEN, state.isOpen);
  }
});

subscribeKey(state, 'topBarVisible', (visible) => {
  if (visible === false) {
    hideTopBar();
  }
});
