import {KITE_WIDGET_STREAM_CREDENTIALS_KEY, WIDGET_INSTANCE, WIDGET_IS_OPEN} from '../constants';

import {INSTANCE_ID} from '../instance';
import {state} from '../state';

export const readStoredStreamCredentials = () => {
  const streamCredentials = localStorage.getItem(KITE_WIDGET_STREAM_CREDENTIALS_KEY);
  return streamCredentials ? JSON.parse(streamCredentials) : undefined;
};

export const isOpenFromLocalStorage = () => localStorage.getItem(WIDGET_IS_OPEN) === 'true';

const storageChangedListener = async ({key, newValue}) => {
  if (key === WIDGET_IS_OPEN) {
    if (state.widgetIsActive && !state.inConference) {
      state.isOpen = newValue === 'true';
    }
  } else if (key === WIDGET_INSTANCE && newValue !== INSTANCE_ID) {
    state.widgetIsActive = false;
  }
};

export const initLocalStorageMonitoring = () => {
  window.addEventListener('storage', storageChangedListener);
};
