import {ThemeColors} from '../../../app/ThemeColors';
import {state} from '../state';
import {addClass, removeClass, toggleClass} from './DOMHelpers';
import {IFrameHelper} from './IFrameHelper';

export const body = document.getElementsByTagName('body')[0];
export const widgetHolder = document.createElement('div');
widgetHolder.setAttribute('data-qa', 'widgetHolder');
export const bubbleHolder = document.createElement('div');
export const chatBubble = document.createElement('button');
chatBubble.setAttribute('data-qa', 'chatButton');

export const topBar = document.createElement('div');
export const topBarCloseBtn = document.createElement('button');
topBarCloseBtn.setAttribute('data-qa', 'closeButton');

export const notificationBubble = document.createElement('span');

export const createBubbleIcon = ({className, target}) => {
  let bubbleClassName = `${className} wx-elements--${state.position}`;

  const bubbleIcon = document.createElement('div');

  bubbleIcon.innerHTML =
    '<svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    `<path d="M10.1172 13.5703C10.5205 13.5703 10.7598 13.29 10.7598 12.8525V10.9863H11.4502C13.002 10.9863 13.9795 10.0088 13.9795 8.43652V2.74902C13.9727 1.14941 12.9746 0.212891 11.3818 0.212891H2.60449C0.963867 0.212891 0.0136719 1.14941 0.0136719 2.74902V8.43652C0.0136719 10.0225 0.963867 10.9863 2.60449 10.9863H6.89062L9.29688 13.1396C9.63184 13.4473 9.84375 13.5703 10.1172 13.5703Z" fill="${ThemeColors.white00}"/>` +
    '</svg>';

  target.appendChild(bubbleIcon);

  target.className = bubbleClassName;
  return target;
};

export const createBubbleHolder = () => {
  addClass(bubbleHolder, 'wx--bubble-holder');
  bubbleHolder.setAttribute('data-qa', 'KiteWidgetUnreadIndicator');
  body.appendChild(bubbleHolder);
};

export const createNotificationBubble = () => {
  addClass(notificationBubble, 'wx--notification');
  return notificationBubble;
};

export const bubbleToggle = (isOpen) => {
  toggleClass(chatBubble, 'wx--hide');
  toggleClass(widgetHolder, 'wx--hide');

  if (!isOpen) {
    chatBubble.focus();
  } else {
    IFrameHelper.getAppFrame().focus();
  }
};

export const toggleUnreadMark = (show) => {
  const bubbleElement = document.querySelector('.wx-widget-bubble');

  if (show) {
    addClass(bubbleElement, 'unread-notification');
  } else {
    removeClass(bubbleElement, 'unread-notification');
  }
};

export const createTopBar = () => {
  topBar.appendChild(topBarCloseBtn);
  topBarCloseBtn.innerHTML =
    '<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="28" height="28">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5303 8.53033C17.8232 8.23744 17.8232 7.76256 17.5303 7.46967C17.2374 7.17678 16.7626 7.17678 16.4697 7.46967L12.5 11.4393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L11.4393 12.5L7.46967 16.4697C7.17678 16.7626 7.17678 17.2374 7.46967 17.5303C7.76256 17.8232 8.23744 17.8232 8.53033 17.5303L12.5 13.5607L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L13.5607 12.5L17.5303 8.53033Z" fill="currentColor"></path>' +
    '</svg>';
  addClass(topBar, 'wx-widget-top-bar');
  widgetHolder.appendChild(topBar);
};

export const hideTopBar = () => {
  addClass(topBar, 'wx-widget-top-bar--hidden');
};
