import {getCss} from '../styles';

const CSS_ID = 'wx_css';

const initialCss = '.wx--bubble-holder, .wx-widget-holder{display: none}';

export const loadCSS = (widgetColor) => {
  let css = document.getElementById(CSS_ID);
  if (!css) {
    css = document.createElement('style');
    css.id = CSS_ID;
    css.type = 'text/css';
    document.body.appendChild(css);
  }
  css.innerHTML = widgetColor ? getCss(widgetColor) : initialCss;
};

export const addClass = (element, className) => {
  element.classList.add(className);
};

export const toggleClass = (element, className) => {
  element.classList.toggle(className);
};

export const removeClass = (element, className) => {
  element.classList.remove(className);
};
