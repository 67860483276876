import {BuildAppNames} from '../constants';

import {APP_BUILD_NAME} from '../appName';

// ===============================
//            XBEES
// ===============================

const xbeesThemeColors = {
  primary_main_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  primary_main_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  primary_main_light_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  primary_main_light_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM,
  primary_main_dark_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  primary_main_dark_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM,
  primary_main_dark_disabled_DM: 'rgba(23, 174, 255, 0.3)', // #17AEFF4D blueAccent255_30__DM
  primary_disabled_LM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  primary_disabled_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  primary_button_disabled_LM: 'rgba(177, 207, 232, 1)', // #B1CFE8FF primaryDisabled_LM
  primary_button_disabled_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  secondary_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  secondary_hover_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  hover_state_LM: 'rgba(22, 76, 116, 0.04)', // #164C74a deepBlue116_04
  hover_state_DM: 'rgba(127, 161, 186, 0.04)', // #7FA1BA0A lightBlue186_04
  text_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM,
  text_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  text_highlight_LM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight
  text_highlight_search_LM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight
  text_highlight_search_DM: 'rgba(6, 128, 222, 1)', // #167BC8 mainBlueLight,
  button_selected_LM: 'rgba(22, 76, 116, 0.08)', // #164C7414 deepBlue116_08
  button_selected_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  button_active_state_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  button_active_state_DM_08: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_active_state_DM_14: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  button_active_state_DM_18: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  button_active_state_DM_50: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  button_hover_active_state_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  button_hover_active_state_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  button_icon_LM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  button_active_icon_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  button_active_icon_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  button_send_hover_LM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  button_send_hover_DM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  button_outlined_text_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  button_outlined_text_204_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  button_outlined_text_250_DM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  button_outlined_text_255_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  primary_button_hover_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  primary_button_hover_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  button_outlined_disabled_text_LM: 'rgba(22, 123, 200, 0.5)', // #167BC880 blue200_50__LM
  button_outlined_disabled_text_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  message_outgoing_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM,
  message_outgoing_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  message_outgoing_quote_background_LM: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  message_outgoing_quote_background_DM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  message_incoming_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  message_incoming_background_DM: 'rgba(20, 27, 34, 1)', // #141B22ff black34__DM
  message_incoming_quote_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  message_incoming_quote_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  message_text_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  container_background_LM: 'rgba(236, 241, 244, 1)', // #ECF1F4ff lightBlue244__LM
  container_background_DM: 'rgba(36, 42, 46, 1)', // #242A2Eff gray46__DM
  input_focused_border_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  input_focused_border_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  chip_background_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  chip_background_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  call_dialog_background_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  call_dialog_background_DM: 'rgba(18, 28, 37, 1)', // #121C25 black37_18_DM
  call_popup_item_background_DM: 'rgba(127, 161, 186, 0.14)', // #7FA1BA23 lightBlue186_14
  call_history_media_button_background_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14
  button_on_gray_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  button_on_gray_hover_08_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_on_gray_hover_18_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  button_on_gray_active_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238
  button_on_gray_active_hover_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  button_on_gray_active_hover_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  button_on_gray_active_08_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  button_on_gray_active_18_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  secondary_button_hover_LM: 'rgba(236, 244, 251, 1)', // #ECF4FBFF secondaryHover__LM
  secondary_button_hover_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  missed_unread_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  missed_unread_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  missed_unread_mark_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  missed_unread_mark_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  close_icon_default_LM: 'rgba(0, 0, 0, 0.54)',
  button_background_DM: '#0680DE', // mainBlueDark
  integration_button_background_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  audio_attachment_LM: 'rgba(255, 255, 255, 0.16)', // #FFFFFF29 white00_16
  file_size_outgoing_LM: '#E2E2E2', // gray04Light
  file_size_outgoing_DM: '#E2E2E2', // gray04Light
  inbox_controls_button_hover_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  inbox_controls_button_hover_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2e blue204_18__DM
  info_frame_accordion_DM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_expanded_DM: 'rgba(32, 39, 46, 1)', // #20272Eff black46__DM
  info_frame_accordion_hover_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  info_frame_accordion_hover_DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12
  members_add_button_hover_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  incoming_message_animation_LM: 'rgba(199, 219, 237, 1)', // #C7DBEDff lightBlue237
  incoming_message_animation_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  outgoing_message_animation_LM: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  outgoing_message_animation_DM: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  main_gradient_start_LM: '',
  main_gradient_start_DM: '',
  main_gradient_finish_LM: '',
  main_gradient_finish_DM: '',
  internal_avatar_gradient_start: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  internal_avatar_gradient_finish: 'rgba(22, 76, 116, 1)', // #164C74ff deepBlue116
  external_avatar_gradient_start: 'rgb(25, 138, 224)', // #198AE0
  external_avatar_gradient_finish: 'rgba(19, 103, 168, 1)', // #1367A8ff blueAccent168__LM
  outgoing_reference_link_background_LM: '#FFFFFF33', // white02Light
  transfer_button_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  transfer_button_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  disabled_transfer_button_text_DM: 'rgba(42, 56, 71, 1)', // #2A3847ff black71__DM
  transfer_item_hover_DM: 'rgba(127, 161, 186, 0.04)', // #7FA1BA0A lightBlue186_04
  brand_LM: 'rgba(232, 125, 7, 1)', // #E87D07FF orange232__LM
  brand_DM: 'rgba(255, 181, 0, 1)', // #FFB500ff orange255
  feedback_button_hover_LM: 'rgba(232, 125, 7, 0.1)', //#E87D0719 orange232_10__LM
  feedback_button_hover_DM: 'rgba(60, 54, 47, 1)', // #3C362FFF gray47
  empty_results_rect_LM: '',
  empty_results_rect_DM: '',
  checkbox_checked_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  checkbox_checked_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  insights_playback_controls_background_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  insights_playback_controls_background_DM: 'rgba(30, 37, 45, 0.8)', // #1E252Dcc black45_80__DM
  insights_playback_controls_button_active_LM: 'transparent',
  insights_playback_controls_button_active_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  insights_playback_controls_button_hover_LM: 'rgba(22, 76, 116, 0.04)', // #164C74a deepBlue116_04
  insights_playback_controls_button_hover_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  insights_playback_controls_border_LM: 'rgba(210,230,246, 1)', // #D2E6F6 lightblue_246__LM
  insights_playback_controls_border_DM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  insights_playback_chunk: 'rgba(53, 164, 250, 1)', // #35A4FAff blue250
  insights_time_code_chunk_LM: 'rgba(19, 103, 168, 0.2)', // #1367A833 blueAccent168_20__LM
  insights_time_code_chunk_DM: 'rgba(19, 103, 168, 0.2)', // #1367A833 blueAccent168_20__LM
  insights_slider_seek_hover_LM: 'rgba(133, 183, 222, 1)', // #85B7DE blue222__LM
  insights_slider_seek_hover_DM: 'rgba(19, 60, 92, 1)', // #133C5C blue_dark_92__DM
  insights_comments_card_background_LM: 'rgb(244, 250, 255)', // #F4FAFF blue_light_255_LM
  insights_comments_card_background_DM: 'rgb(18, 36, 50)', // #122432 blue_light_50_DM
  insights_comments_card_hex_background_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  insights_comments_card_hex_background_DM: 'rgba(42, 56, 71, 1)', // #2A3847ff black71__DM
  insights_comments_button_hover_background_DM: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  insights_translate_button_hover_DM: 'rgba(127, 161, 186, 0.08)', // #7FA1BA14 lightBlue186_08
  insights_transcription_highlight_LM: 'rgba(22, 123, 200, 0.18)', // #167BC82e blue200_18__LM
  insights_transcription_highlight_DM: 'rgba(23, 174, 255, 0.3)', // #17AEFF4D blueAccent255_30__DM
  pinned_indicator_active_regular_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  pinned_indicator_active_regular_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_active_dynamic_LM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_active_dynamic_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  pinned_indicator_LM: 'rgba(177, 207, 232, 1)', // #B1CFE8FF primaryDisabled_LM
  pinned_indicator_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  pinned_indicator_corner_LM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  pinned_indicator_corner_DM: 'rgba(130, 186, 229, 0.18)', //#82BAE52E lightBlue130_18__DM
  secondary_button_background__LM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_title_active_hover__LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  info_frame_accordion_title_active_hover__DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12
  predefined_reply_button_hover_LM: 'rgba(22, 123, 200, 0.12)', // #167BC81E blue200_12__LM
  predefined_reply_button_hover_DM: 'rgba(127, 161, 186, 0.12)', // #7FA1BA1F lightBlue186_12

  integrations_icon_action_regular_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  integrations_icon_action_regular_DM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  integrations_icon_action_field_LM: 'rgba(139, 143, 156, 1)', // #8B8F9Cff grayBlue156__LM
  integrations_icon_action_field_DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff gray162__LM
  integrations_icon_action_regular_hover_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_icon_action_regular_hover_DM: 'rgb(23,174,255)', // #35A4FAff blue250
  integrations_icon_action_regular_hover_mui_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  integrations_icon_color_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_icon_color_DM: 'rgba(23, 174, 255, 1)', // #17AEFFff blueAccent255__DM
  integrations_action_background_LM: 'rgba(22, 123, 200, 1)', // #167BC8ff blue200__LM
  integrations_action_background_DM: 'rgba(5, 118, 204, 1)', // #0576CCff blue204__DM
  integrations_alert_background_LM: 'rgba(22, 123, 200, 0.08)', // #167BC814 blue200_08__LM
  integrations_alert_background_DM: 'rgba(5, 118, 204, 0.14)', // blue204_14__DM
  integrations_button_disabled_background_LM: 'rgba(152, 165, 174, 0.06)', // #98A5AE0F gray174_06
  integrations_button_disabled_background_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  integrations_accordion_badge_LM: 'rgba(199, 219, 237, 1)', // #C7DBEDff lightBlue237
  integrations_accordion_badge_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  integrations_icon_next_step_LM: 'rgb(177, 207, 232)', // #B1CFE8 blue232_LM
  integrations_icon_next_step_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80 blue204_50__DM
  integrations_sf_badge_LM: 'rgba(210, 225, 238, 1)', // #D2E1EEff lightBlue238__LM
  integrations_sf_badge_DM: 'rgba(5, 118, 204, 0.08)', // #0576CC14 blue204_08__DM
  integrations_icon_text_editor_LM: 'rgb(23,174,255)', // #35A4FAff blue250
  integrations_icon_text_editor_DM: 'rgba(5, 118, 204, 0.18)', // #0576CC2E blue204_18__DM
  integrations_tooltip_background_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_tooltip_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  integrations_tooltip_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  integrations_tooltip_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_steps_unfinished_background: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
};

// ===============================
//            XHOPPERS
// ===============================

const xhoppersColorStyles = {
  primary_main_LM: 'rgba(63, 134, 66, 1)', // #3F8642ff
  primary_main_DM: 'rgba(63, 134, 66, 1)', // #3F8642ff
  primary_main_dark_LM: 'rgba(32, 84, 34, 1)', // #205422ff
  primary_main_dark_DM: 'rgba(105, 205, 80, 1)', // #69CD50ff
  primary_main_light_LM: 'rgba(85, 186, 55, 1)', // #55BA37ff
  primary_main_light_DM: 'rgba(52, 114, 54, 1)', // #347236
  primary_disabled_LM: 'rgba(212, 233, 212, 1)', // #D4E9D4ff
  primary_disabled_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A2E
  primary_disabled_text_LM: 'rgba(192, 222, 192, 1)', // #C0DEC0
  primary_disabled_text_DM: 'rgba(5, 118, 204, 0.5)', // #0576CC80
  primary_disabled_hover_LM: 'rgba(63, 134, 66, 0.28)', // #3F864247
  secondary_hover_state_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  secondary_hover_state_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A1F
  secondary_hover_on_white_state_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  states_active_LM: 'rgba(85, 186, 55, 0.12)', // #55BA371F
  states_active_DM: 'rgba(119, 203, 136, 0.12)', // #77CB881F
  states_hover_LM: 'rgba(0, 0, 0, 0.04)', // #0000000A
  states_hover_DM: 'rgba(185, 200, 193, 0.08)', // #B9C8C114
  secondary_background_message_incoming_LM: 'rgba(243, 243, 243, 1)', // #F3F3F3
  secondary_background_message_incoming_DM: 'rgba(27, 32, 37, 1)', // #1B2025
  secondary_background_message_outgoing_LM: 'rgba(228, 244, 223, 1)', // #E4F4DFFF
  secondary_background_message_outgoing_DM: 'rgba(85, 186, 55, 0.16)', // #55BA3729
  secondary_background_message_action_outgoing_LM: 'rgba(212, 233, 212, 1)', // #D4E9D4
  secondary_background_message_action_outgoing_DM: 'rgba(199, 219, 237, 1)', // #C7DBED
  secondary_background_active_state_LM: 'rgba(244, 244, 244, 1)', // #F4F4F4
  secondary_background_active_state_DM: 'rgba(18, 28, 37, 1)', // #121C25
};

const xhoppersThemeColors = {
  primary_main_LM: xhoppersColorStyles.primary_main_LM,
  primary_main_DM: xhoppersColorStyles.primary_main_DM,
  primary_main_light_LM: xhoppersColorStyles.primary_main_light_LM,
  primary_main_light_DM: xhoppersColorStyles.primary_main_light_DM,
  primary_main_dark_LM: xhoppersColorStyles.primary_main_dark_LM,
  primary_main_dark_DM: xhoppersColorStyles.primary_main_dark_DM,
  primary_main_dark_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  primary_disabled_LM: xhoppersColorStyles.primary_disabled_LM,
  primary_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  primary_button_disabled_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  primary_button_disabled_DM: xhoppersColorStyles.primary_disabled_DM,
  secondary_hover_LM: xhoppersColorStyles.secondary_hover_state_LM,
  secondary_hover_DM: xhoppersColorStyles.states_active_DM,
  hover_state_LM: xhoppersColorStyles.states_hover_LM,
  hover_state_DM: xhoppersColorStyles.states_hover_DM,
  text_LM: xhoppersColorStyles.primary_main_dark_LM,
  text_DM: xhoppersColorStyles.primary_main_dark_DM,
  text_highlight_LM: xhoppersColorStyles.primary_main_LM,
  text_highlight_search_LM: 'rgba(6, 151, 12, 1)', // #06970Cff
  text_highlight_search_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_selected_LM: xhoppersColorStyles.states_active_LM,
  button_selected_DM: xhoppersColorStyles.states_active_DM,
  button_active_state_LM: 'rgba(235, 247, 231, 1)', // #EBF7E7
  button_active_state_DM_08: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_14: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_18: xhoppersColorStyles.primary_disabled_DM,
  button_active_state_DM_50: xhoppersColorStyles.primary_disabled_DM,
  button_hover_active_state_LM: xhoppersColorStyles.primary_disabled_LM,
  button_hover_active_state_DM: 'rgba(22, 200, 58, 0.28)', //#16C83A47
  button_icon_LM: 'rgba(147, 147, 147, 1)', // #939393
  button_active_icon_LM: xhoppersColorStyles.primary_main_LM,
  button_active_icon_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_text_LM: xhoppersColorStyles.primary_main_LM,
  button_outlined_text_204_DM: xhoppersColorStyles.primary_main_DM,
  button_outlined_text_250_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_text_255_DM: xhoppersColorStyles.primary_main_dark_DM,
  primary_button_hover_LM: xhoppersColorStyles.primary_main_dark_LM,
  primary_button_hover_DM: xhoppersColorStyles.primary_main_DM,
  button_send_hover_LM: xhoppersColorStyles.primary_main_light_LM,
  button_send_hover_DM: xhoppersColorStyles.primary_main_dark_DM,
  button_outlined_disabled_text_LM: xhoppersColorStyles.primary_disabled_text_LM,
  button_outlined_disabled_text_DM: 'rgba(81, 204, 93, 0.12)', //#51CC5D1F green204_12__DM
  message_outgoing_background_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  message_outgoing_background_DM: xhoppersColorStyles.primary_main_LM,
  message_outgoing_quote_background_LM: 'rgba(211, 237, 203, 1)', // #D3EDCB
  message_outgoing_quote_background_DM: 'rgba(82, 147, 85, 1)', // #529355
  message_incoming_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  message_incoming_background_DM: xhoppersColorStyles.secondary_background_message_incoming_DM,
  message_incoming_quote_background_LM: 'rgba(233, 233, 233, 1)', // #E9E9E9
  message_incoming_quote_background_DM: 'rgba(36, 42, 46, 1)', // #242A2Eff gray46__DM
  message_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  message_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_LM: xhoppersColorStyles.primary_main_dark_LM,
  message_link_DM: xhoppersColorStyles.primary_main_dark_DM,
  container_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  container_background_DM: xhoppersColorStyles.secondary_background_message_incoming_DM,
  input_focused_border_LM: xhoppersColorStyles.primary_disabled_text_LM,
  input_focused_border_DM: xhoppersColorStyles.primary_disabled_text_DM,
  chip_background_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  chip_background_DM: xhoppersColorStyles.primary_disabled_hover_LM,
  call_dialog_background_LM: xhoppersColorStyles.secondary_background_active_state_LM,
  call_dialog_background_DM: xhoppersColorStyles.secondary_background_active_state_DM,
  call_popup_item_background_DM: xhoppersColorStyles.states_active_DM,
  call_history_media_button_background_DM: 'rgba(152, 165, 174, 0.12)',
  secondary_button_hover_LM: xhoppersColorStyles.secondary_hover_on_white_state_LM,
  secondary_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  button_on_gray_active_LM: xhoppersColorStyles.secondary_hover_state_LM,
  button_on_gray_active_hover_LM: xhoppersColorStyles.secondary_hover_state_LM,
  button_on_gray_active_hover_DM: xhoppersColorStyles.primary_disabled_text_DM,
  button_on_gray_active_08_DM: xhoppersColorStyles.states_active_DM,
  button_on_gray_active_18_DM: xhoppersColorStyles.primary_disabled_DM,
  button_on_gray_hover_LM: xhoppersColorStyles.states_hover_LM,
  button_on_gray_hover_08_DM: xhoppersColorStyles.states_active_DM,
  button_on_gray_hover_18_DM: xhoppersColorStyles.states_active_DM,
  missed_unread_background_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  missed_unread_background_DM: xhoppersColorStyles.primary_disabled_DM,
  missed_unread_mark_background_LM: xhoppersColorStyles.primary_main_LM,
  missed_unread_mark_background_DM: xhoppersColorStyles.primary_main_dark_DM,
  close_icon_default_LM: 'rgba(147, 147, 147, 1)', //#939393
  button_background_DM: xhoppersColorStyles.primary_main_DM,
  integration_button_background_DM: xhoppersColorStyles.states_active_LM,
  audio_attachment_LM: 'rgba(0, 0, 0, 0.08)', // #000000CC black00_08
  file_size_outgoing_LM: xhoppersColorStyles.primary_main_LM,
  file_size_outgoing_DM: 'rgba(255, 255, 255, 0.6)', // #FFFFFF99 white00_60__DM
  inbox_controls_button_hover_LM: 'rgba(235, 247, 231, 1)', // #EBF7E7
  inbox_controls_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  info_frame_accordion_DM: 'transparent',
  info_frame_accordion_expanded_DM: 'rgba(185, 200, 193, 0.06)', // #B9C8C10F
  info_frame_accordion_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  info_frame_accordion_hover_DM: xhoppersColorStyles.states_active_DM,
  members_add_button_hover_DM: xhoppersColorStyles.primary_main_light_DM,
  incoming_message_animation_LM: xhoppersColorStyles.primary_disabled_DM,
  incoming_message_animation_DM: xhoppersColorStyles.primary_main_light_DM,
  outgoing_message_animation_LM: 'rgba(36, 194, 60, 0.5)', // #24C23C80 green194_50__LM
  outgoing_message_animation_DM: xhoppersColorStyles.primary_main_light_LM,
  main_gradient_start_LM: xhoppersColorStyles.primary_main_light_LM,
  main_gradient_start_DM: 'rgba(63, 151, 67, 1)', // #3F9743ff
  main_gradient_finish_LM: xhoppersColorStyles.primary_main_LM,
  main_gradient_finish_DM: 'rgba(43, 89, 45, 1)', // #2B592D
  internal_avatar_gradient_start: 'rgba(108, 127, 104, 1)', // #6C7F68
  internal_avatar_gradient_finish: 'rgba(78, 89, 78, 1)', // #4E594E
  external_avatar_gradient_start: 'rgba(76, 164, 70, 1)', // #4CA446
  external_avatar_gradient_finish: 'rgba(54, 114, 57, 1)', // #367239
  outgoing_reference_link_background_LM: xhoppersColorStyles.states_active_LM,
  transfer_button_LM: xhoppersColorStyles.primary_disabled_LM,
  transfer_button_DM: xhoppersColorStyles.primary_disabled_DM,
  disabled_transfer_button_text_DM: 'rgba(81, 204, 93, 0.12)', // #51CC5D1F green204_12__DM
  transfer_item_hover_DM: xhoppersColorStyles.states_active_DM,
  brand_LM: xhoppersColorStyles.primary_main_LM,
  brand_DM: xhoppersColorStyles.primary_main_dark_DM,
  feedback_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  feedback_button_hover_DM: xhoppersColorStyles.secondary_background_active_state_DM,
  empty_results_rect_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  empty_results_rect_DM: 'rgba(81, 204, 93, 0.12)', // #51CC5D1f green204_12__DM
  checkbox_checked_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  checkbox_checked_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_playback_controls_background_LM: xhoppersColorStyles.secondary_background_message_incoming_LM,
  insights_playback_controls_background_DM: 'rgba(30, 37, 45, 0.8)', // #1E252Dcc black45_80__DM
  insights_playback_controls_button_active_LM: xhoppersColorStyles.secondary_hover_state_LM,
  insights_playback_controls_button_active_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_playback_controls_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  insights_playback_controls_button_hover_DM: xhoppersColorStyles.primary_disabled_DM,
  insights_playback_controls_border_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  insights_playback_controls_border_DM: 'rgba(255, 255, 255, 0.12)', // #FFFFFF1F white00_12
  insights_playback_chunk: xhoppersColorStyles.primary_main_LM,
  insights_time_code_chunk_LM: xhoppersColorStyles.primary_disabled_text_LM,
  insights_time_code_chunk_DM: 'rgba(10, 49, 12, 1)', // #0A310C
  insights_slider_seek_hover_LM: 'rgba(109, 181, 112, 1)', // #6DB570,
  insights_slider_seek_hover_DM: 'rgba(27, 85, 29, 1)', // #1B551D,
  insights_comments_card_background_LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  insights_comments_card_background_DM: xhoppersColorStyles.secondary_hover_state_DM,
  insights_comments_card_hex_background_LM: xhoppersColorStyles.primary_main_light_LM,
  insights_comments_card_hex_background_DM: xhoppersColorStyles.primary_main_LM,
  insights_comments_button_hover_background_DM: 'rgba(22, 200, 58, 0.12)', // #16C83A1F
  insights_translate_button_hover_DM: xhoppersColorStyles.states_hover_DM,
  insights_transcription_highlight_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  insights_transcription_highlight_DM: xhoppersColorStyles.primary_disabled_DM,
  pinned_indicator_active_regular_LM: xhoppersColorStyles.primary_main_LM,
  pinned_indicator_active_regular_DM: xhoppersColorStyles.primary_main_dark_DM,
  pinned_indicator_active_dynamic_LM: xhoppersColorStyles.primary_main_LM,
  pinned_indicator_active_dynamic_DM: xhoppersColorStyles.primary_main_dark_DM,
  pinned_indicator_LM: 'rgba(85, 186, 55, 0.7)', // #55BA37B2
  pinned_indicator_DM: xhoppersColorStyles.primary_main_DM,
  pinned_indicator_corner_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  pinned_indicator_corner_DM: 'rgba(58, 63, 60, 1)', //#3A3F3C
  secondary_button_background__LM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_title_active_hover__LM: xhoppersColorStyles.secondary_background_message_outgoing_LM,
  info_frame_accordion_title_active_hover__DM: xhoppersColorStyles.states_active_DM,
  predefined_reply_button_hover_LM: xhoppersColorStyles.primary_disabled_LM,
  predefined_reply_button_hover_DM: xhoppersColorStyles.primary_disabled_hover_LM,

  integrations_icon_action_regular_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_regular_DM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  integrations_icon_action_field_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_field_DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff gray162__LM
  integrations_icon_action_regular_hover_LM: xhoppersColorStyles.primary_main_LM,
  integrations_icon_action_regular_hover_DM: xhoppersColorStyles.primary_main_DM,
  integrations_icon_action_regular_hover_mui_DM: xhoppersColorStyles.primary_main_DM,
  integrations_icon_color_LM: xhoppersColorStyles.primary_main_LM,
  integrations_icon_color_DM: xhoppersColorStyles.primary_main_DM,
  integrations_action_background_LM: xhoppersColorStyles.primary_main_LM,
  integrations_action_background_DM: xhoppersColorStyles.primary_main_DM,
  integrations_alert_background_LM: xhoppersColorStyles.secondary_hover_state_LM,
  integrations_alert_background_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_button_disabled_background_LM: xhoppersColorStyles.primary_disabled_LM,
  integrations_button_disabled_background_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_accordion_badge_LM: xhoppersColorStyles.primary_disabled_DM,
  integrations_accordion_badge_DM: xhoppersColorStyles.states_active_DM,
  integrations_icon_next_step_LM: xhoppersColorStyles.primary_disabled_hover_LM,
  integrations_icon_next_step_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_sf_badge_LM: xhoppersColorStyles.secondary_background_active_state_LM,
  integrations_sf_badge_DM: xhoppersColorStyles.primary_disabled_DM,
  integrations_icon_text_editor_LM: xhoppersColorStyles.primary_main_dark_DM,
  integrations_icon_text_editor_DM: xhoppersColorStyles.secondary_hover_state_DM,
  integrations_tooltip_background_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_tooltip_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  integrations_tooltip_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  integrations_tooltip_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_steps_unfinished_background: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
};

// ===============================
//         COLLABORATION
// ===============================

const collaborationColorStyles = {
  primary_main_LM: 'rgba(53, 130, 201, 1)', // #3582C9
  primary_main_DM: 'rgba(48, 120, 189, 1)', // #3078BD
  primary_main_dark_LM: 'rgba(31, 106, 180, 1)', // #1F6AB4
  primary_main_dark_DM: 'rgba(0, 170, 255, 1)', // #00AAFF
  primary_main_light_LM: 'rgba(0, 170, 255, 1)', // #00AAFF
  primary_main_light_DM: 'rgba(74, 160, 242, 1)', // #4AA0F2
  primary_disabled_LM: 'rgba(53, 130, 201, 0.28)', // #3582C947
  primary_disabled_DM: 'rgba(48, 120, 189, 0.2)', // #3078BD33
  primary_disabled_text_LM: 'rgba(146, 185, 215, 1)', // #92B9D7
  primary_disabled_text_DM: 'rgba(38, 122, 221, 0.4)', // #267ADD66
  primary_disabled_hover_LM: 'rgba(53, 130, 201, 0.24)', // #3582C93D
  secondary_hover_state_LM: 'rgba(53, 130, 201, 0.1)', // #3582C91A
  secondary_hover_state_DM: 'rgba(104, 174, 255, 0.12)', // #68AEFF1F
  secondary_hover_on_white_state_LM: 'rgba(53, 130, 201, 0.16)', // #3582C929
  states_active_LM: 'rgba(53, 130, 201, 0.1)', // #3582C91A
  states_active_DM: 'rgba(104, 174, 255, 0.12)', // #68AEFF1F
  states_hover_LM: 'rgba(0, 0, 0, 0.04)', // #0000000A
  states_hover_DM: 'rgba(188, 194, 209, 0.06)', // #BCC2D10F
  secondary_background_message_incoming_LM: 'rgba(243, 243, 243, 1)', // #F3F3F3
  secondary_background_message_incoming_DM: 'rgba(27, 32, 37, 1)', // #1B2025
  secondary_background_message_outgoing_LM: 'rgba(53, 130, 201, 1)', // #3582C9
  secondary_background_message_outgoing_DM: 'rgba(48, 120, 189, 1)', // #3078BD
  secondary_background_message_action_outgoing_LM: 'rgba(47, 118, 185, 1)', // #2F76B9
  secondary_background_message_action_outgoing_DM: 'rgba(30, 96, 156, 1)', // #1E609C
  secondary_background_active_state_LM: 'rgba(244, 244, 244, 1)', // #F4F4F4
  secondary_background_active_state_DM: 'rgba(18, 28, 37, 1)', // #121C25
};

const collaborationThemeColors = {
  primary_main_LM: collaborationColorStyles.primary_main_LM,
  primary_main_DM: collaborationColorStyles.primary_main_DM,
  primary_main_light_LM: collaborationColorStyles.primary_main_light_LM,
  primary_main_light_DM: collaborationColorStyles.primary_main_light_DM,
  primary_main_dark_LM: collaborationColorStyles.primary_main_dark_LM,
  primary_main_dark_DM: collaborationColorStyles.primary_main_dark_DM,
  primary_main_dark_disabled_DM: collaborationColorStyles.primary_disabled_DM,
  primary_disabled_LM: collaborationColorStyles.primary_disabled_LM,
  primary_disabled_DM: collaborationColorStyles.primary_disabled_DM,
  primary_button_disabled_LM: collaborationColorStyles.primary_disabled_hover_LM,
  primary_button_disabled_DM: collaborationColorStyles.primary_disabled_DM,
  secondary_hover_LM: collaborationColorStyles.secondary_hover_state_LM,
  secondary_hover_DM: collaborationColorStyles.states_active_DM,
  hover_state_LM: collaborationColorStyles.states_hover_LM,
  hover_state_DM: collaborationColorStyles.states_hover_DM,
  text_LM: collaborationColorStyles.primary_main_dark_LM,
  text_DM: collaborationColorStyles.primary_main_dark_DM,
  text_highlight_LM: collaborationColorStyles.primary_main_LM,
  text_highlight_search_LM: collaborationColorStyles.primary_main_LM,
  text_highlight_search_DM: collaborationColorStyles.primary_main_dark_DM,
  button_selected_LM: collaborationColorStyles.states_active_LM,
  button_selected_DM: collaborationColorStyles.states_active_DM,
  button_active_state_LM: collaborationColorStyles.secondary_hover_state_LM,
  button_active_state_DM_08: collaborationColorStyles.primary_disabled_DM,
  button_active_state_DM_14: collaborationColorStyles.primary_disabled_DM,
  button_active_state_DM_18: collaborationColorStyles.primary_disabled_DM,
  button_active_state_DM_50: collaborationColorStyles.primary_disabled_DM,
  button_hover_active_state_LM: collaborationColorStyles.secondary_hover_on_white_state_LM,
  button_hover_active_state_DM: 'rgba(104, 174, 255, 0.2)', // #68AEFF33
  button_icon_LM: 'rgba(147, 147, 147, 1)', // #939393
  button_active_icon_LM: collaborationColorStyles.primary_main_LM,
  button_active_icon_DM: collaborationColorStyles.primary_main_dark_DM,
  button_outlined_text_LM: collaborationColorStyles.primary_main_LM,
  button_outlined_text_204_DM: collaborationColorStyles.primary_main_DM,
  button_outlined_text_250_DM: collaborationColorStyles.primary_main_dark_DM,
  button_outlined_text_255_DM: collaborationColorStyles.primary_main_dark_DM,
  primary_button_hover_LM: collaborationColorStyles.primary_main_dark_LM,
  primary_button_hover_DM: collaborationColorStyles.primary_main_DM,
  button_send_hover_LM: collaborationColorStyles.primary_main_light_LM,
  button_send_hover_DM: collaborationColorStyles.primary_main_dark_DM,
  button_outlined_disabled_text_LM: collaborationColorStyles.primary_disabled_text_LM,
  button_outlined_disabled_text_DM: collaborationColorStyles.primary_disabled_text_DM,
  message_outgoing_background_LM: collaborationColorStyles.primary_main_LM,
  message_outgoing_background_DM: collaborationColorStyles.primary_main_DM,
  message_outgoing_quote_background_LM: collaborationColorStyles.secondary_background_message_action_outgoing_LM,
  message_outgoing_quote_background_DM: collaborationColorStyles.secondary_background_message_action_outgoing_DM,
  message_incoming_background_LM: collaborationColorStyles.secondary_background_message_incoming_LM,
  message_incoming_background_DM: collaborationColorStyles.secondary_background_message_incoming_DM,
  message_incoming_quote_background_LM: collaborationColorStyles.states_hover_LM,
  message_incoming_quote_background_DM: 'rgba(36, 42, 46, 1)', // #242A2Eff gray46__DM
  message_text_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  message_link_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  container_background_LM: collaborationColorStyles.secondary_background_message_incoming_LM,
  container_background_DM: collaborationColorStyles.secondary_background_message_incoming_DM,
  input_focused_border_LM: collaborationColorStyles.primary_disabled_text_LM,
  input_focused_border_DM: collaborationColorStyles.primary_disabled_text_DM,
  chip_background_LM: collaborationColorStyles.primary_disabled_hover_LM,
  chip_background_DM: collaborationColorStyles.primary_disabled_hover_LM,
  call_dialog_background_LM: 'rgba(217, 234, 249, 1)', // #D9EAF9
  call_dialog_background_DM: collaborationColorStyles.secondary_background_active_state_DM,
  call_popup_item_background_DM: collaborationColorStyles.states_active_DM,
  call_history_media_button_background_DM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  secondary_button_hover_LM: collaborationColorStyles.secondary_hover_on_white_state_LM,
  secondary_button_hover_DM: collaborationColorStyles.primary_disabled_DM,
  button_on_gray_active_LM: collaborationColorStyles.secondary_hover_state_LM,
  button_on_gray_active_hover_LM: collaborationColorStyles.secondary_hover_state_LM,
  button_on_gray_active_hover_DM: collaborationColorStyles.primary_disabled_text_DM,
  button_on_gray_active_08_DM: collaborationColorStyles.states_active_DM,
  button_on_gray_active_18_DM: collaborationColorStyles.primary_disabled_DM,
  button_on_gray_hover_LM: collaborationColorStyles.states_hover_LM,
  button_on_gray_hover_08_DM: collaborationColorStyles.states_active_DM,
  button_on_gray_hover_18_DM: collaborationColorStyles.states_active_DM,
  missed_unread_background_LM: collaborationColorStyles.primary_main_LM,
  missed_unread_background_DM: collaborationColorStyles.primary_main_DM,
  missed_unread_mark_background_LM: collaborationColorStyles.primary_main_LM,
  missed_unread_mark_background_DM: collaborationColorStyles.primary_main_dark_DM,
  close_icon_default_LM: 'rgba(147, 147, 147, 1)', //#939393
  button_background_DM: collaborationColorStyles.primary_main_DM,
  integration_button_background_DM: collaborationColorStyles.states_active_LM,
  audio_attachment_LM: 'rgba(255, 255, 255, 0.16)', // #FFFFFF29 white00_16
  file_size_outgoing_LM: '#E2E2E2', // gray04Light
  file_size_outgoing_DM: 'rgba(255, 255, 255, 0.6)', // #FFFFFF99 white00_60__DM
  inbox_controls_button_hover_LM: collaborationColorStyles.secondary_hover_state_LM,
  inbox_controls_button_hover_DM: collaborationColorStyles.primary_disabled_DM,
  info_frame_accordion_DM: 'transparent',
  info_frame_accordion_expanded_DM: collaborationColorStyles.states_hover_DM,
  info_frame_accordion_hover_LM: collaborationColorStyles.secondary_hover_on_white_state_LM,
  info_frame_accordion_hover_DM: collaborationColorStyles.states_active_DM,
  members_add_button_hover_DM: collaborationColorStyles.primary_main_light_DM,
  incoming_message_animation_LM: collaborationColorStyles.primary_disabled_LM,
  incoming_message_animation_DM: collaborationColorStyles.primary_disabled_text_DM,
  outgoing_message_animation_LM: collaborationColorStyles.primary_main_light_LM,
  outgoing_message_animation_DM: collaborationColorStyles.primary_main_light_LM,
  main_gradient_start_LM: 'rgba(32, 89, 149, 1)', // #205995
  main_gradient_start_DM: 'rgba(0, 56, 101, 1)', // #003865
  main_gradient_finish_LM: collaborationColorStyles.primary_main_LM,
  main_gradient_finish_DM: 'rgba(33, 100, 164, 1)', // #2164A4
  internal_avatar_gradient_start: 'rgba(5, 67, 117, 1)', // #054375
  internal_avatar_gradient_finish: 'rgba(28, 98, 160, 1)', // #1C62A0
  external_avatar_gradient_start: 'rgba(47, 119, 187, 1)', // #2F77BB
  external_avatar_gradient_finish: 'rgba(65, 148, 225, 1)', // #4194E1
  outgoing_reference_link_background_LM: '#FFFFFF33', // white02Light
  transfer_button_LM: collaborationColorStyles.primary_disabled_LM,
  transfer_button_DM: collaborationColorStyles.primary_disabled_DM,
  disabled_transfer_button_text_DM: collaborationColorStyles.primary_disabled_text_DM,
  transfer_item_hover_DM: collaborationColorStyles.states_active_DM,
  brand_LM: collaborationColorStyles.primary_main_dark_LM,
  brand_DM: collaborationColorStyles.primary_main_dark_DM,
  feedback_button_hover_LM: 'rgba(225, 236, 250, 1)', // #E1ECFA
  feedback_button_hover_DM: collaborationColorStyles.secondary_background_active_state_DM,
  empty_results_rect_LM: collaborationColorStyles.secondary_background_message_outgoing_LM,
  empty_results_rect_DM: collaborationColorStyles.primary_disabled_text_DM,
  checkbox_checked_LM: collaborationColorStyles.primary_disabled_hover_LM,
  checkbox_checked_DM: collaborationColorStyles.secondary_hover_state_DM,
  insights_playback_controls_background_LM: '',
  insights_playback_controls_background_DM: '',
  insights_playback_controls_button_active_LM: '',
  insights_playback_controls_button_active_DM: '',
  insights_playback_controls_button_hover_LM: '',
  insights_playback_controls_button_hover_DM: '',
  insights_playback_controls_border_LM: '',
  insights_playback_controls_border_DM: '',
  insights_playback_chunk: '',
  insights_time_code_chunk_LM: '',
  insights_time_code_chunk_DM: '',
  insights_slider_seek_hover_LM: '',
  insights_slider_seek_hover_DM: '',
  insights_comments_card_background_LM: '',
  insights_comments_card_background_DM: '',
  insights_comments_card_hex_background_LM: '',
  insights_comments_card_hex_background_DM: '',
  insights_comments_button_hover_background_DM: '',
  insights_translate_button_hover_DM: collaborationColorStyles.states_hover_DM,
  insights_transcription_highlight_LM: collaborationColorStyles.primary_disabled_hover_LM,
  insights_transcription_highlight_DM: collaborationColorStyles.primary_disabled_DM,
  pinned_indicator_active_regular_LM: collaborationColorStyles.primary_main_LM,
  pinned_indicator_active_regular_DM: collaborationColorStyles.primary_main_dark_DM,
  pinned_indicator_active_dynamic_LM: collaborationColorStyles.primary_main_LM,
  pinned_indicator_active_dynamic_DM: collaborationColorStyles.primary_main_dark_DM,
  pinned_indicator_LM: 'rgba(53, 130, 201, 0.4)', // #3682C966
  pinned_indicator_DM: collaborationColorStyles.primary_disabled_text_DM,
  pinned_indicator_corner_LM: collaborationColorStyles.primary_disabled_hover_LM,
  pinned_indicator_corner_DM: collaborationColorStyles.primary_disabled_DM,
  secondary_button_background__LM: 'rgba(152, 165, 174, 0.12)', // #98A5AE1f gray174_12
  info_frame_accordion_title_active_hover__LM: collaborationColorStyles.secondary_hover_on_white_state_LM,
  info_frame_accordion_title_active_hover__DM: collaborationColorStyles.states_active_DM,
  predefined_reply_button_hover_LM: collaborationColorStyles.primary_disabled_LM,
  predefined_reply_button_hover_DM: collaborationColorStyles.primary_disabled_hover_LM,

  integrations_icon_action_regular_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_regular_DM: 'rgba(117, 118, 121, 1)', // #757679ff gray117__LM
  integrations_icon_action_field_LM: 'rgb(139,156,143)', // #8B8F9Cff
  integrations_icon_action_field_DM: 'rgba(157, 159, 162, 1)', // #9D9FA2ff gray162__LM
  integrations_icon_action_regular_hover_LM: collaborationColorStyles.primary_main_LM,
  integrations_icon_action_regular_hover_DM: collaborationColorStyles.primary_main_DM,
  integrations_icon_action_regular_hover_mui_DM: collaborationColorStyles.primary_main_DM,
  integrations_icon_color_LM: collaborationColorStyles.primary_main_LM,
  integrations_icon_color_DM: collaborationColorStyles.primary_main_DM,
  integrations_action_background_LM: collaborationColorStyles.primary_main_LM,
  integrations_action_background_DM: collaborationColorStyles.primary_main_DM,
  integrations_alert_background_LM: collaborationColorStyles.secondary_hover_state_LM,
  integrations_alert_background_DM: collaborationColorStyles.primary_disabled_DM,
  integrations_button_disabled_background_LM: collaborationColorStyles.primary_disabled_LM,
  integrations_button_disabled_background_DM: collaborationColorStyles.primary_disabled_DM,
  integrations_accordion_badge_LM: collaborationColorStyles.primary_disabled_DM,
  integrations_accordion_badge_DM: collaborationColorStyles.states_active_DM,
  integrations_icon_next_step_LM: collaborationColorStyles.primary_disabled_hover_LM,
  integrations_icon_next_step_DM: collaborationColorStyles.primary_disabled_DM,
  integrations_sf_badge_LM: collaborationColorStyles.secondary_background_active_state_LM,
  integrations_sf_badge_DM: collaborationColorStyles.primary_disabled_DM,
  integrations_icon_text_editor_LM: collaborationColorStyles.primary_main_dark_DM,
  integrations_icon_text_editor_DM: collaborationColorStyles.secondary_hover_state_DM,
  integrations_tooltip_background_LM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_tooltip_background_DM: 'rgba(43, 51, 59, 1)', // #2B333Bff gray59__DM
  integrations_tooltip_text_LM: 'rgba(41, 44, 49, 1)', // #292C31ff black49__LM
  integrations_tooltip_text_DM: 'rgba(255, 255, 255, 1)', // #FFFFFFff white00
  integrations_steps_unfinished_background: 'rgba(156, 156, 156, 0.18)', // #9C9C9C2E
};

export default function getAppSpecificThemeColors() {
  let appSpecificThemeColors;

  switch (APP_BUILD_NAME) {
    case BuildAppNames.XBEES:
      appSpecificThemeColors = xbeesThemeColors;
      break;
    case BuildAppNames.XHOPPERS:
      appSpecificThemeColors = xhoppersThemeColors;
      break;
    case BuildAppNames.COLLABORATION:
      appSpecificThemeColors = collaborationThemeColors;
      break;
    default:
      appSpecificThemeColors = xbeesThemeColors;
  }

  return appSpecificThemeColors;
}
