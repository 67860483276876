import {ThemeColors} from '../../app/ThemeColors';

export function getCss(widgetColor) {
  return `
.wx-widget-holder {
  box-shadow: 0 8px 16px rgba(13, 20, 32, 0.14);
  opacity: 1;
  will-change: transform, opacity;
  transform: translateY(0);
  overflow: hidden !important;
  position: fixed !important;
  transition: opacity 0.2s linear, transform 0.25s linear, width 0.5s linear, height 0.5s linear, max-height 0.5s linear;
  z-index: 2147483000 !important;
  background-color: ${ThemeColors.white00};
}

.wx-widget-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100% !important;
  width: 100% !important;
  max-height: 100vh !important;
}

.wx-widget-bubble {
  background: ${widgetColor};
  border-radius: 100px;
  border-width: 0px;
  bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .16) !important;
  cursor: pointer;
  height: 64px;
  padding: 0px;
  position: fixed;
  user-select: none;
  width: 64px;
  z-index: 2147483000 !important;
  outline: 0;
}

.wx-widget-bubble.unread-notification::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: ${ThemeColors.red238__LM};
  border-radius: 100%;
  top: 0px;
  right: 0px;
  border: 2px solid ${ThemeColors.white00};
  transition: background 0.2s ease;
}

.wx-widget-bubble.wx-widget--expanded {
  bottom: 24px;
  display: flex;
  height: 48px !important;
  width: auto !important;
  align-items: center;
}

.wx-widget-bubble.wx-widget--expanded div {
  align-items: center;
  color: ${ThemeColors.white00};
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding-right: 20px;
  width: auto !important;
}

.wx-widget-bubble.wx-widget--expanded img {
  height: 20px;
  margin: 14px 8px 14px 16px;
  width: 20px;
}

.wx-widget-bubble.wx-elements--left {
  left: 20px;
}

.wx-widget-bubble.wx-elements--right {
  right: 20px;
}

.wx-widget-bubble:hover {
  background: ${widgetColor};
  box-shadow: 0 8px 32px rgba(0, 0, 0, .4) !important;
}

.wx-widget-bubble svg {
  all: revert;
  padding-top: 2px;
  height: 24px;
  margin: 20px;
  width: 24px;
}

@media only screen and (min-width: 667px) {
  .wx-widget-holder.wx-elements--left {
    left: 20px;
 }
  .wx-widget-holder.wx-elements--right {
    right: 20px;
 }
}

.wx--close:hover {
  opacity: 1;
}

.wx--close::before, .wx--close::after {
  background-color: ${ThemeColors.white00};
  content: ' ';
  display: inline;
  height: 24px;
  left: 32px;
  position: absolute;
  top: 20px;
  width: 2px;
}

.wx--close::before {
  transform: rotate(45deg);
}

.wx--close::after {
  transform: rotate(-45deg);
}

.wx--hide {
  bottom: -100vh !important;
  transform: translateY(40px);
  top: unset !important;
  opacity: 0;
  visibility: hidden !important;
  z-index: -1 !important;
}

.wx-widget--without-bubble {
  bottom: 20px !important;
}

.wx-widget-top-bar {
  background: ${ThemeColors.button_selected_LM};
  height: 28px;
  position: absolute;
  top: 0;
  width: 100%;
}
.wx-widget-top-bar--hidden {
  display: none;
}

.wx-widget-top-bar button {
  width: 28px;
  height: 28px;
  float: right;
  color: inherit;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  justify-content: center;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  margin: 0 12px 0 0;
  color: ${ThemeColors.gray131__LM}
}

@media only screen and (max-width: 667px) {
  .wx-widget-holder {
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
 }

 .wx-widget-holder iframe {
    min-height: 100% !important;
  }

  .wx-widget-bubble.wx--close {
    bottom: 60px;
    opacity: 0;
    visibility: hidden !important;
    z-index: -1 !important;
  }
}

@media only screen and (min-width: 667px) {
  .wx-widget-holder {
    border-radius: 16px;
    bottom: 20px;
    height: calc(85% - 64px - 20px);
    max-height: 590px !important;
    min-height: 250px !important;
    width: 400px !important;
  }

  .wx-widget-holder, .wx-widget-holder iframe {
    max-width: 1200px;
  }

  .wx-widget-holder[class].wx--maximized,
  .wx-widget-holder[class].wx--maximized iframe {
    height: calc(90vh - 65px) !important;
    max-height: calc(90vh - 65px) !important;
    width: 85vw !important;
    max-width: 1200px;
  }
}
`;
}
